<template>
  <v-card id="card-modal-assign-driver" class=" elevation-4">
    <v-card-title class="justify-start py-2 card-modaltitle" >
      <div class="pl-4">
        <h3 class="primary--text titleOffersMasiveModal">
          {{
            $t(
              "components.views.trip.no_offer_assign.modal_offer_trips.offer_trip"
            )
          }}
        </h3>
      </div>
      <v-btn
        depressed
        text
        color="primary"
        class="cont"
        @click.stop="$emit('closeForm')"
      >
        <v-icon class="mr-1">fa-solid fa-xmark</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pa-0">
      <v-container>
        <v-form v-model="validSimple">
          <v-row class="pa-3">
            <v-col cols="12" >
              <SelectAutocomplete
                v-model="companys"
                return-object
                :data="dataBusiness"
                item-text="businessName"
                item-value="idCompany"
                dense
                :label="
                  $t(
                    'components.views.trip.no_offer_assign.modal_offer_trips.company_transporter_name'
                  )
                "
                :placeholder="
                  $t(
                    'components.views.trip.no_offer_assign.modal_offer_trips.search_company_transporter_name'
                  )
                "
                :rules="[
                  required(
                    $t(
                      'components.views.trip.no_offer_assign.modal_offer_trips.company_transporter_name'
                    ),
                    1
                  ),
                ]"
                required
                :selected="companys"
                :init="false"
                chips
                multiple
                v-bind="$attrs"
                v-on="$listeners"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggleSimple">
                    <v-list-item-action>
                      <v-icon :class="icon" />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          $t(
                            "components.views.trip.no_offer_assign.modal_offer_trips.select_all"
                          )
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip v-bind="attrs" :input-value="selected" label small>
                    <span class="pr-2">
                      {{ item.businessName }}
                    </span>
                    <v-icon small @click="parent.selectItem(item)">
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
              </SelectAutocomplete>
            </v-col>
          </v-row>
          <v-row class="personalize card-modalbtn  pa-0 ma-0" style=" display: flex; align-items: center; justify-content: flex-end;">
            <v-col cols="12" class="pa-0 ma-0">
              <v-row class="justify-end pa-0 ma-0">
                <v-btn
                  color="primary"
                  class="ml-4 "
                  depressed
                  rounded
                  style="height: 30px;"
                  :disabled="!validSimple"
                  @click="offerSimple()"
                >
                  <v-icon small class="mr-1">fa-solid fa-check</v-icon>
                  {{
                    $t(
                      "components.views.trip.no_offer_assign.modal_offer_trips.offer"
                    )
                  }}
                </v-btn>
                <v-btn
                  color="grey lighten-1 white--text"
                  class="ml-4 "
                  depressed
                  rounded
                  style="height: 30px;"
                  @click.stop="$emit('closeForm')"
                >
                  <v-icon small size="15" class="mr-1">
                    fa-solid fa-xmark
                  </v-icon>
                  {{
                    $t(
                      "components.views.trip.no_offer_assign.modal_offer_trips.cancel"
                    )
                  }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import validate from "@/plugins/validate";
export default {
  props: {
    selectedTrips: {
      default: [],
    },
  },
  data() {
    return {
      ...validate,
      validSimple: false,
      companys: 0,
      dataBusiness: [],
    };
  },
  computed: {
    likesAllBusiness() {
      return this.companys.length === this.dataBusiness.length;
    },
    icon() {
      if (this.likesAllBusiness) return "fa-solid fa-square-check";
      return "fa-solid fa-square";
    },
  },
  watch: {
    companys(newVal) {
      if (newVal.length == 0) {
        this.companys = 0;
      }
    },
  },
  mounted() {
    this.loadBusiness();
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("loader", ["showLoader"]),

    async offerSimple() {
      this.showLoader({
        show: true,
      });

      let transportCompanies = this.companys.map((element) => {
        return {
          name: element.businessName,
          idCompany: element.idCompany,
        };
      });

      let idMonitoringOrder = this.selectedTrips.map((element) => {
        return element.idMonitoringOrder;
      });

      let params = {
        transportCompanies,
        idMonitoringOrder,
      };

      await this.axios
        .post("/Offers/MassivePublish", params)
        .then((response) => {
          this.showSnack({
            text: response.data.messages,
            title: this.$t(
              "components.views.trip.no_offer_assign.modal_offer_trips.success"
            ),
            name: "success",
          });
          if (response.data.errors.length > 0) {
            setTimeout(() => {
              this.showSnack({
                text:
                  this.$t(
                    "components.views.trip.no_offer_assign.modal_offer_trips.error_offers_finalized"
                  ) + response.data.errors,
                title: this.$t(
                  "components.views.trip.no_offer_assign.modal_offer_trips.warning"
                ),
                name: "warning",
                timeout: 3000,
              });
            }, 3000);
          }
        })
        .catch((error) => {
          this.showSnack({
            text: error.response.data,
            title: this.$t(
              "components.views.trip.no_offer_assign.modal_offer_trips.save_error"
            ),
            name: "error",
          });
        })
        .finally(() => {
          this.showLoader({
            show: false,
          });
          this.$emit("reloadPage");
        });
    },

    toggleSimple() {
      this.$nextTick(() => {
        this.companys = this.likesAllBusiness ? [] : this.dataBusiness.slice();
      });
    },
    async loadBusiness() {
      await this.axios
        .get("/TransportCompany")
        .then((response) => {
          this.dataBusiness = response.data ? response.data : [];
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
@import './ModalOffersMassiveStyle.scss';
</style>
